<template>
  <v-container fluid class="fill-height text-center">
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="carts"
        :items-per-page="-1"
        :search="searchCart"
        :loading="loadingData"
        :expanded="expanded"
        single-expand
        show-expand
        item-key="id"
        sort-by="id"
        disable-sort
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: false,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title>Gestión de Carritos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-contact"
                label="Buscar.."
                name="findcontact"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchCart"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-responsive>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline"
                  >Esta seguro que desea eliminar este carrito?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Proceder</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAttend" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  Estado del carrito
                </v-card-title>

                <v-card-text>
                  <v-select
                    v-model="selectedStatus"
                    :items="['aceptado', 'cancelado']"
                    label="Selecciona un estado"
                  ></v-select>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeAttend">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="attendItemConfirm">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDetail" max-width="800px">
              <v-card>
                <v-card-title class="text-h5 headline">
                  {{ serviceItem.category }}
                </v-card-title>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text>
                      <b> Nombre: </b>
                      {{ serviceItem.name }}
                    </v-card-text>

                    <v-card-text v-if="serviceItem.profile">
                      <b> Email: </b>
                      {{ serviceItem.profile.email }}
                    </v-card-text>

                    <v-card-text v-if="serviceItem.profile">
                      <b> Sitio Web: </b>
                      {{ serviceItem.profile.web_page }}
                    </v-card-text>

                    <v-card-text v-if="serviceItem.profile">
                      <b> Teléfono: </b>
                      {{
                        serviceItem.profile.phone
                          ? serviceItem.profile.phone
                          : serviceItem.profile.phone1
                      }}
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-img :src="serviceItem.image" aspect-ratio="1"></v-img>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDetail">
                    Cancelar
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="doAttendItem(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            <span>Carrito Atendido?</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid class="fill-height flex">
              <v-row dense justify="start" align="start" class="ma-0">
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Nombres y Apellidos: </span>
                    </v-card-title>
                    <v-card-title class="text-subtitle-1">
                      {{ item.name + " " + item.surnames }}
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Correo Electrónico: </span>
                    </v-card-title>
                    <v-card-title class="text-subtitle-1">
                      {{ item.email }}
                    </v-card-title>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Servicios solicitados: </span>
                    </v-card-title>
                    <v-list>
                      <v-list-item-group v-if="item.services.length > 0">
                        <v-list-item
                          v-for="(service, index) in item.services"
                          :key="index"
                          @click="serviceDetail(service)"
                        >
                          <v-list-item-avatar>
                            <v-img :src="service.image"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content class="text-left">
                            <v-list-item-title
                              >{{ service.category }}:
                              {{ service.name }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item v-else>
                        <v-list-item-content
                          >No hay servicios solicitados.</v-list-item-content
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Teléfono: </span>
                    </v-card-title>
                    <v-card-title class="text-subtitle-1">
                      {{ item.phone | formatPhone("MX") }}
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>

      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import CartService from "@/providers/CartService";

export default {
  name: "AdminCartComponent",

  data: () => ({
    headers: [
      { text: "", value: "data-table-expand", width: 5 },

      { text: "Id", value: "id", align: " d-none", width: 0 }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "E-mail", value: "email" },
      { text: "Estado", value: "state" },
      { text: "Acciones", value: "actions", width: 100, sortable: false },
    ],

    expanded: [],
    carts: [],
    loadingData: false,
    searchCart: "",
    dialogDelete: false,
    snackBar: false,
    snackText: "",
    dialogAttend: false,
    dialogDetail: false,
    editedIndex: -1,

    editedItem: {
      id: 0,
      name: "",
      email: "",
      phone: "",
    },
    selectedStatus: "",

    serviceItem: {},
  }),

  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
            "USA +1 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        case "MX":
          return (
            "MEX +52 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        default:
          return country;
      }
    },
  },

  methods: {
    serviceDetail(service) {
      this.serviceItem = service;

      // console.log(this.serviceItem);
      this.dialogDetail = true;
    },

    getCarts() {
      this.loadingData = true;
      CartService.getAllCarts().then((record) => {
        this.carts = record.shoppingCarts.map((cart) => {
          const services = [];

          const relationMap = {
            doctors: {
              category: "Doctor",
              name: "name",
              id: "id",
              image: "pictures",
            },
            hospitales: {
              category: "Hospital",
              name: "name",
              id: "id",
              image: "pictures",
            },
            clinics: {
              category: "Clínica",
              name: "name",
              id: "id",
              image: "pictures",
            },
            restaurants: {
              category: "Restaurant",
              name: "name",
              id: "id",
              image: "pictures",
            },

            leisures: {
              category: "Ocio",
              name: "name",
              id: "id",
              image: "pictures",
            },
            hotels: {
              category: "Hotel",
              name: "name",
              id: "id",
              image: "pictures",
            },
            pharmaceutics: {
              category: "Farmacia",
              name: "name",
              id: "id",
              image: "pictures",
            },
            laboratories: {
              category: "Laboratorio",
              name: "name",
              id: "id",
              image: "pictures",
            },

            transportations: {
              category: "Transporte",
              name: "name",
              id: "id",
              image: "pictures",
            },

            vineyards: {
              category: "Viñedo",
              name: "name",
              id: "id",
              image: "pictures",
            },
            houses: {
              category: "Casa",
              name: "name",
              id: "id",
              image: "pictures",
            },
          };

          for (const [relation, properties] of Object.entries(relationMap)) {
            if (cart[relation].length > 0) {
              cart[relation].forEach((item) => {
                services.push({
                  category: properties.category,
                  name: item[properties.name],
                  id: item[properties.id],
                  image: item.pictures[0] ? item.pictures[0].url : null,
                  profile: item,
                });
              });
            }
          }

          return {
            ...cart,
            services,
          };
        });

        // console.log(this.carts);
        this.loadingData = false;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    deleteItemConfirm() {
      this.carts.splice(this.editedIndex, 1);
      CartService.deleteRecord(this.editedItem.id);
      // console.log('Item deleted:', this.editedItem);
      this.closeDelete();
      this.snackText = "Registro eliminado con éxito.";
      this.snackBar = true;
    },

    deleteItem(item) {
      this.editedIndex = this.carts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
      this.dialogDelete = true;
    },

    closeAttend() {
      this.dialogAttend = false;
    },

    closeDetail() {
      this.dialogDetail = false;
      this.serviceItem = {};
    },

    doAttendItem(item) {
      this.editedItem = Object.assign({}, item);
      this.selectedStatus = "";
      this.dialogAttend = true;
    },

    attendItemConfirm() {
      if (this.selectedStatus) {
        CartService.updateRecord(this.editedItem.id, this.selectedStatus).then(
          (result) => {
            this.closeAttend();
            if (result.success) {
              this.snackText = "Carrito modificado!";
            } else {
              this.snackText = "Un error impidió procesar este carrito!";
            }
            this.snackBar = true;
            this.getCarts();
          }
        );
      } else {
        this.snackText = "Ingrese un estado";
        this.snackBar = true;
      }
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogAttend(val) {
      val || this.closeAttend();
    },

    dialogDetail(val) {
      val || this.closeDetail();
    },
  },

  mounted() {
    this.carts = [];

    this.getCarts();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
