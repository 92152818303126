<template>
  <admin-cart-component />
</template>

<script>
import AdminCartComponent from "../../components/admin/AdminCartComponent.vue";

export default {
  name: "AdminCart",
  title: "Gestión de carritos | Turismo BC",
  components: {
    AdminCartComponent,
  },
};
</script>

<style scoped></style>
