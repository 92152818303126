/***********************************************/
// Capa de Servicios para el modulo de carrito.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + store.getters.getToken,
    },
  };

  constructor() {
    this.#baseUrl = store.getters.getBaseURL;
  }

  async getAllCarts() {
    try {
      let response = await axios.get(this.#baseUrl + "/shopping-carts");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addCart(formData) {
    return axios
      .post(this.#baseUrl + "/add-to-shopping-cart", formData)
      .then((response) => {
        // console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return this.handleError(error);
      });
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      // console.log('Error response: %s', error.response.data.message);
      valueError = error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
      // console.log(error.request);
      // console.error('Error request: ', error.request.data);
    } else {
      valueError = error.message;
      // console.log('Error', error.message);
    }
    return valueError;
  }

  deleteRecord(id) {
    let result = null;
    // console.log('Deleting Record: %s ', id);
    axios
      .delete(`${this.#baseUrl}/shopping-carts/${id}`)
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
      })
      /* handle error */
      .catch((error) => {
        // console.log('Error: ', response);
        result = this.handleError(error);
      });
    return result;
  }

  async updateRecord(id, state) {
    let result = null;

    try {
      let response = await axios.patch(
        `${this.#baseUrl}/shopping-carts/${id}/update-state`,
        {
          state: state,
        }
      );

      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      result = this.handleError(error);
      return result;
    }
  }
})();
